import Cards from "./Cards";
import Information from "./Information"
import Range from "./Range";
import Services from "./Services";


function Main() {
  return (
    <>
    <Information/>
    <Range/>
   <Services/>
   <Cards/>
    </>
  );
}

export default Main;
